import styled from "styled-components";
import { green, red } from "../style";
import { TiTimes } from "react-icons/ti";

/*image*/

import loading2 from "./loading2.gif";

const ModalBodyContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;

  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  z-index: 1000;
`;
const LoadingBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  //background: #fff;
`;

const LoadingModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.4);
  z-index: 1000;
`;
const LoadingImage = styled.div`
  & img {
    width: 300px;
    height: auto;
  }
`;
export const LoadingModal = () => {
  return (
    <LoadingModalContainer>
      <LoadingBody>
        <LoadingImage>
          <img src={loading2} alt="loading"></img>
        </LoadingImage>
      </LoadingBody>
    </LoadingModalContainer>
  );
};

/* confirm modal */

const ConfirmBody = styled.div`
  border-radius: 4px;
  background: #fff;
  min-width: 500px;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.6);

  @media screen and (max-width: 768px) {
    width: calc (100vw - 2rem);
  }
`;

const ConfirmFooter = styled.div`
  display: flex;

  justify-content: flex-end;
  padding: 1rem;
  padding-right: 2rem;
  border-top: 2px solid #eee;
`;

const ConfirmHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.2rem;
  padding-right: 1rem;
  border-bottom: 2px solid #eee;
`;
const ConfirmButton = styled.button`
  border: 0;
  outline: 0;
  padding: 0.7rem;
  background: ${(props) => props.background || "#fff"};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${(props) => (props.type === "icon" ? "2rem" : "1.2rem")};
  border-radius: 4px;
  cursor: pointer;
  color: ${(props) => (props.type === "icon" ? "#333" : "#fff")};
  & path {
    stroke: #fff;
  }

  &:focus {
    outline: none;
    border: none;
  }
`;

const ConfirmMessage = styled.div`
  width: 100%;
  padding: 2rem 2rem;
  font-size: 1.4rem;
  text-transform: lowercase;
`;

export const ConfirmModal = ({ message, cancleHandler, confirmHandler }) => {
  return (
    <ModalBodyContainer>
      <ConfirmBody>
        <ConfirmHeader>
          <ConfirmButton type="icon" onClick={cancleHandler}>
            <TiTimes />
          </ConfirmButton>
        </ConfirmHeader>
        <ConfirmMessage>{message}</ConfirmMessage>
        <ConfirmFooter>
          <ConfirmButton
            background={green}
            style={{ marginRight: "1rem" }}
            onClick={confirmHandler}
          >
            Confirm
          </ConfirmButton>
          <ConfirmButton background={red} onClick={cancleHandler}>
            Cancle
          </ConfirmButton>
        </ConfirmFooter>
      </ConfirmBody>
    </ModalBodyContainer>
  );
};
