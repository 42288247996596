import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { greenLite } from "../../style";
import { Link } from "react-router-dom";

export const NewsListHeading = styled(Grid)`
  background-color: #666;
  font-size: 1.6rem;
  color: #fff;
  margin-bottom: 1rem;

  & div {
    padding: 0.5rem;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
`;

export const RowContainer = styled(Grid)`
  background: ${greenLite};
  font-size: 1.4rem;
  border-radius: 4px;
  margin-bottom: 1rem;

  & > div {
    padding: 1rem;
    margin: 0;
    align-items: center;
    border: 1px solid #eee;

    & > p {
      text-transform: capitalize;
    }
  }
`;
export const ActivityContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  padding: 0 1rem;
`;

export const ActivityButton = styled.button`
  border: 0;
  outline: 0;
  padding: 0.7rem;
  background: ${(props) => props.background};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
  & path {
    stroke: #fff;
  }

  &:focus {
    outline: none;
    border: none;
  }
`;

export const ActivityLink = styled(Link)`
  border: 0;
  outline: 0;
  padding: 0.7rem;
  background: ${(props) => props.background};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  font-size: 2rem;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;

  & path {
    stroke: #fff;
  }
  &:focus {
    outline: none;
    border: none;
  }
`;
