import styled from "styled-components";
import { primaryColor } from "../style";

const HeadingWraper = styled.div`
  padding: 2rem;
  font-size: 3rem;
  background: ${(props) => props.background || primaryColor};
  display: flex;
  color: #fff;
  border: 1px solid #eee;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
`;

const HeadingIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
  color: #fff;
  font-size: 3rem;

  path {
    stroke: #fff;
  }
`;

const HeadingText = styled.h1`
  font-size: 2rem;
  text-transform: uppercase;
`;
const Heading = ({ icon, children, background }) => {
  return (
    <>
      <HeadingWraper background={background}>
        <HeadingIcon>{icon}</HeadingIcon>
        <HeadingText>{children}</HeadingText>
      </HeadingWraper>
    </>
  );
};
export default Heading;
