import React, { useState, useEffect } from "react";

import ProjectListRow from "../components/Project/ProjectListRow";
import { ProjectRowHeading } from "../components/Project/ProjectList.Item";

import { Grid } from "@material-ui/core";
import { green, stdDarkLite } from "../style";
import { LoadingModal, ConfirmModal } from "../Utility/Modal";
import ItemCard from "../Utility/ItemCard";

/*icon*/
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { FiArchive } from "react-icons/fi";
import axios from "axios";

/** url */

function ProjectList() {
  /* token */
  const token = window.localStorage.getItem("acc_mk");
  const headers = {};
  const [modal, setModal] = useState({
    confirmModal: false,
    loading: true,
  });

  const [projects, setProjects] = useState([]);
  const [projectsData, setProjectsData] = useState({
    total: 0,
    active: 0,
    archived: 0,
  });

  const [deleteId, setDeleteId] = useState(null);

  const hideModal = (name) => {
    setModal((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
  };

  const showModal = (name) => {
    setModal((prev) => {
      return {
        ...prev,
        [name]: true,
      };
    });
  };

  const deleteButtonHandler = (id) => {
    setDeleteId(id);
    showModal("confirmModal");
  };

  const confirmDelete = () => {
    hideModal("confirmModal");
    showModal("loading");
    let content = {
      params: {
        token,
      },
      headers: headers,
    };
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/projects/delete/` + deleteId,
        content
      )
      .then((response) => {
        hideModal("loading");
        if (response.data === 1) {
          setProjects((prev) => {
            return prev.filter((project) => project.id !== deleteId);
          });
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    let content = {
      params: {
        token,
      },
      headers: headers,
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/projects/get/list`, content)
      .then((response) => {
        setProjects(response.data);
        hideModal("loading");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //projects state update effect
  useEffect(() => {
    let active = 0;
    let total = 0;
    projects.forEach((project) => {
      total++;
      if (project.status === 1) active++;
    });

    setProjectsData({
      total,
      active,
      archived: total - active,
    });
  }, [projects]);

  return (
    <>
      <section className="project-list">
        {/* item card */}
        <Grid container style={{ marginTop: "3rem" }}>
          <Grid
            item
            xs={12}
            md={4}
            style={{ marginBottom: "3rem", padding: "0 1rem" }}
          >
            <ItemCard
              title="Total Post"
              value={projectsData.total}
              icon={<AiOutlineFundProjectionScreen />}
              color="#fff"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{ marginBottom: "2rem", padding: "0 1rem" }}
          >
            <ItemCard
              title="Active Project"
              value={projectsData.active}
              icon={<VscActivateBreakpoints />}
              background={green}
              color="#fff"
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{ marginBottom: "2rem", padding: "0 1rem" }}
          >
            <ItemCard
              title="Archived Project"
              value={projectsData.archived}
              icon={<FiArchive />}
              background={stdDarkLite}
            />
          </Grid>
        </Grid>
        {/* list header */}
        <ProjectRowHeading container>
          <Grid xs={6} item container>
            Title
          </Grid>
          <Grid xs={2} item container style={{ background: "#888" }}>
            Sector
          </Grid>
          <Grid xs={2} item container>
            Status
          </Grid>
          <Grid xs={2} item container style={{ background: "#888" }}>
            Activity
          </Grid>
        </ProjectRowHeading>

        {/* List component */}

        {projects.map((project) => {
          return (
            <ProjectListRow
              status={project.status}
              title={project.title}
              sector={project.sector}
              id={project.id}
              key={project.id}
              deleteButtonHandler={deleteButtonHandler}
            />
          );
        })}
      </section>

      {/* confirm modal */}
      {modal.confirmModal && (
        <ConfirmModal
          message="are you sure to delete?"
          cancleHandler={() => {
            hideModal("confirmModal");
          }}
          confirmHandler={confirmDelete}
        ></ConfirmModal>
      )}
      {/* Loading */}
      {modal.loading && <LoadingModal />}
    </>
  );
}

export default ProjectList;
