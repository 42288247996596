import React from "react";
import {
  NavigationContainer,
  NavigationHeader,
  MenuContainer,
  CloseButton,
} from "./Navigation.Item";

import MenuItemContainer from "./MenuItemContainer";
import { RiDashboardFill } from "react-icons/ri";
import { FaTimes, FaThList, FaPeopleCarry } from "react-icons/fa";
import { GoProject } from "react-icons/go";
import { AiOutlineApartment, AiOutlinePlus } from "react-icons/ai";
import { BiNews } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";
import { SiAboutDotMe } from "react-icons/si";
function Navigation() {
  return (
    <NavigationContainer>
      <NavigationHeader>
        <h2>Admin</h2>
        <CloseButton variant="contained">
          <FaTimes />
        </CloseButton>
      </NavigationHeader>

      <MenuContainer>
        <MenuItemContainer
          title="Dashboard"
          menuIcon={<RiDashboardFill />}
          to="/dashboard"
        ></MenuItemContainer>

        <MenuItemContainer
          title="Project"
          menuIcon={<GoProject />}
          to=""
          type="parent"
        >
          <MenuItemContainer
            title="Add"
            menuIcon={<AiOutlinePlus />}
            to="/add-project"
          ></MenuItemContainer>

          <MenuItemContainer
            title="List"
            menuIcon={<FaThList />}
            to="/project-list"
          ></MenuItemContainer>
        </MenuItemContainer>

        <MenuItemContainer title="News" menuIcon={<BiNews />} type="parent">
          <MenuItemContainer
            title="Add"
            menuIcon={<AiOutlinePlus />}
            to="/add-news"
          ></MenuItemContainer>

          <MenuItemContainer
            title="List"
            menuIcon={<FaThList />}
            to="/news-list"
          ></MenuItemContainer>
        </MenuItemContainer>
        <MenuItemContainer
          title="Employer"
          menuIcon={<FaPeopleCarry />}
          type="parent"
        >
          <MenuItemContainer
            title="Add"
            menuIcon={<AiOutlinePlus />}
            to="/add-employer"
          ></MenuItemContainer>

          <MenuItemContainer
            title="List"
            menuIcon={<FaThList />}
            to="/employer-list"
          ></MenuItemContainer>
        </MenuItemContainer>
        <MenuItemContainer
          title="Partner Page"
          menuIcon={<IoIosPeople />}
          to="/partner"
        ></MenuItemContainer>
        <MenuItemContainer
          title="Project Overview"
          menuIcon={<AiOutlineApartment />}
          to="/project-overview"
        ></MenuItemContainer>
        <MenuItemContainer
          title="About us"
          menuIcon={<SiAboutDotMe />}
          to="/aboutus"
        ></MenuItemContainer>
      </MenuContainer>
    </NavigationContainer>
  );
}

export default Navigation;
