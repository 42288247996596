import React, { useState, useEffect } from "react";
import {
  InputField,
  SelectField,
  Button,
  FileField,
  Alert,
  TextAreaField,
} from "../Utility/Form";
import Heading from "../Utility/Heading";
import { green, primaryColor } from "../style";
import { AiOutlineFileSearch, AiOutlinePlus } from "react-icons/ai";
import { Grid } from "@material-ui/core";

import axios from "axios";
import { useParams } from "react-router-dom";
import TextEditor from "../components/Editor/TextEditor";
import { LoadingModal } from "../Utility/Modal";

//ck

/** api url */

function AddProject() {
  /* token */
  const token = window.localStorage.getItem("acc_mk");
  const headers = {};
  /*url data */
  const urlData = useParams();

  const [isUiReady, setIsUiReady] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    title: "Add Project",
  });
  /* form data */

  const [inputField, setInputField] = useState({
    title: "",
    from: "",
    to: "",
    area: "",
    sectorid: "",
    description: "",
    status: "",
    image: "",
    meta_description: "",
    meta_keyword: "",
    meta_robots: "",
  });

  const [fieldError, setFieldError] = useState({
    title: {
      isError: false,
      errorMessage: "",
      isRequired: true,
      length: 150,
    },
    from: {
      isError: false,
      errorMessage: "",
      isRequired: true,
      length: 4,
    },
    to: {
      isError: false,
      errorMessage: "",
      isRequired: true,
      length: 4,
    },
    area: {
      isError: false,
      errorMessage: "",
      isRequired: true,
      length: 255,
    },
    sectorid: {
      isError: false,
      errorMessage: "",
      isRequired: true,
    },
    status: {
      isError: false,
      errorMessage: "",
      isRequired: true,
    },
    image: {
      isError: false,
      errorMessage: "",
      isRequired: true,
    },
    meta_description: {
      isError: false,
      errorMessage: "",
      isRequired: false,
      length: 300,
    },
    meta_keyword: {
      isError: false,
      errorMessage: "",
      isRequired: false,
      length: 100,
    },
    meta_robots: {
      isError: false,
      errorMessage: "",
      isRequired: false,
      length: 10,
    },
    description: {
      isError: false,
      errorMessage: "",
      isRequired: true,
    },
  });

  const [sectors, setSectors] = useState();
  // const [valid, setValid] = useState(false);
  // const [apiStatus, setApiStatus] = useState(false);

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    message: "",
  });

  const onChangeHandler = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    console.log(name);
    /* handle image file differently */
    if (name === "image") {
      value = e.target.files[0];
    }

    console.log(value);
    /* updating the state */
    setInputField((prev) => {
      return { ...prev, [name]: value };
    });
  };

  /* handle response -> after submiting the form */

  const handleRespone = (response) => {
    scrollToHead();
    if (response.data === 1) {
      for (const key in inputField) {
        let target = document.querySelector("[name~=" + key);
        if (key == "image") {
          target.value = null;
        }
        if (target) {
          target.style.borderColor = "";
        }
      }
      setInputField({
        title: "",
        from: "",
        to: "",
        area: "",
        sectorid: "",
        description: "",
        status: "",
        image: "",
        meta_description: "",
        meta_keyword: "",
        meta_robots: "",
      });
    }

    if (response.data === 1) {
      triggerAlert("Successfully added", "success");
    } else if (response.data === -3) {
      document.querySelector("[name~=title]").style.borderColor = "red";
      triggerAlert("Title should be unique", "danger");
    } else {
      triggerAlert("Reload The page and try again", "danger");
    }
  };

  const handleUpdateResponse = (response) => {
    scrollToHead();
    if (response.data === 1) {
      for (const key in inputField) {
        let target = document.querySelector("[name~=" + key);
        if (key == "image") {
          target.value = null;
        }
        if (target) {
          target.style.borderColor = "";
        }
      }
      // setInputField({
      //   title: "",
      //   from: "",
      //   to: "",
      //   area: "",
      //   sectorid: "",
      //   description: "",
      //   status: "",
      //   image: "",
      // });
    }

    if (response.data === 1) {
      triggerAlert("Successfully Updated", "success");
    } else if (response.data === -3) {
      document.querySelector("[name~=title]").style.borderColor = "red";
      triggerAlert("Title should be unique", "danger");
    } else {
      triggerAlert("Reload The page and try again", "danger");
    }
  };

  /* top alert box */
  const triggerAlert = (message, type) => {
    scrollToHead();

    setAlert({
      isOpen: true,
      message,
      type,
    });
  };

  /* field validation */
  const fieldValidation = () => {
    let isValid = true;
    let fieldErrorClone = { ...fieldError };

    for (let key in fieldErrorClone) {
      let htmlElem = document.querySelector(`[name~=${key}]`);

      if (fieldErrorClone[key].isRequired && inputField[key] === "") {
        console.log(key);
        console.log(inputField);
        isValid = false;
        fieldErrorClone[key].isError = true;
        fieldErrorClone[key].errorMessage = "* Field Should Not be Empty";
        if (htmlElem) {
          htmlElem.style.borderColor = "red";
        }
      } else if (
        inputField[key] !== "" &&
        fieldErrorClone[key] != null &&
        inputField[key] != null &&
        fieldErrorClone[key].length < inputField[key].length
      ) {
        isValid = false;
        fieldErrorClone[key].isError = true;
        fieldErrorClone[
          key
        ].errorMessage = `length should not exceed ${fieldErrorClone[key].length}`;
        if (htmlElem) {
          htmlElem.style.borderColor = "red";
        }
      } else {
        fieldErrorClone[key].isError = false;
        if (htmlElem) {
          htmlElem.style.borderColor = "green";
        }
      }
    }
    setFieldError(fieldErrorClone);
    return isValid;
  };

  /* check every required field */
  const isEveryThingOk = () => {
    if (fieldValidation()) return true;
    else {
      triggerAlert("Fix The error", "danger");
      return false;
    }
  };

  const scrollToHead = () => {
    let element = document.querySelector("#root");
    element.scrollIntoView({ behavior: "smooth" });
  };
  const handleSubmit = () => {
    /* check every required field */
    if (!isEveryThingOk()) {
      return;
    }

    setIsUiReady(false);
    /* creating form data from state */
    let data = new FormData();
    for (let key in inputField) {
      data.append(key, inputField[key]);
    }
    data.append("token", token);
    console.log(inputField);
    /* sending request to server */

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/project/insert`, data)
      .then((response) => {
        setIsUiReady(true);
        handleRespone(response);
      })
      .catch((error) => {
        setIsUiReady(true);
        console.log(error);
      });
  };

  const handleUpdate = () => {
    /* check every required field */
    if (!isEveryThingOk()) {
      return;
    }

    /* creating form data from state */
    let data = new FormData();

    for (let key in inputField) {
      data.append(key, inputField[key]);
    }
    data.append("token", token);

    /* sending request to server */
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/project/update/` + urlData.id,
        data,
        {
          headers: {
            "Content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        handleUpdateResponse(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* first render */
  useEffect(() => {
    scrollToHead();
    let data;
    let content = {
      headers: {
        ...headers,
      },
      params: {
        token,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/sectors/get`, content)
      .then((response) => {
        setSectors(response.data);
        setIsUiReady(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /*urlData update */
  useEffect(() => {
    if (urlData.hasOwnProperty("id")) {
      let content = {
        params: {
          token,
        },

        headers: {
          ...headers,
        },
      };
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/projects/get/` + urlData.id,
          content
        )
        .then((response) => {
          setInputField((prev) => {
            let newData = {};
            for (const key in prev) {
              newData[key] =
                response.data[key] == null ? "" : response.data[key];
            }
            newData["image"] = 0;
            console.log(newData);
            setIsUiReady(true);
            return newData;
          });
        });
      setPageInfo((prev) => {
        return {
          ...prev,
          title: "Update Project",
        };
      });
    } else {
      setPageInfo((prev) => {
        return {
          ...prev,
          title: "Add Project",
        };
      });
      setInputField((prev) => {
        let newData = {};
        for (const key in prev) {
          newData[key] = "";
        }
        return newData;
      });

      setAlert((prev) => {
        return {
          ...prev,
          isOpen: false,
        };
      });
    }
  }, [urlData]);

  return (
    <>
      <section style={{ marginBottom: "4rem" }}>
        <Heading
          icon={<AiOutlinePlus />}
          background={urlData.hasOwnProperty("id") ? green : primaryColor}
        >
          {pageInfo.title}
        </Heading>
        {/* failed alert */}
        {alert.isOpen && (
          <Alert
            type={alert.type}
            cancleHandler={() => {
              setAlert((prev) => {
                return { ...prev, isOpen: false };
              });
            }}
          >
            {alert.message}
          </Alert>
        )}
        {isUiReady && (
          <>
            <InputField
              type="text"
              value={inputField.title}
              label="Project Title"
              name="title"
              onChangeHandler={onChangeHandler}
              isError={fieldError.title.isError}
              errorMessage={fieldError.title.errorMessage}
            ></InputField>
            <Grid container>
              <Grid item xs={12} md={6}>
                <InputField
                  type="number"
                  value={inputField.from}
                  label="From"
                  name="from"
                  onChangeHandler={onChangeHandler}
                  isError={fieldError.from.isError}
                  errorMessage={fieldError.from.errorMessage}
                ></InputField>
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingLeft: "1rem" }}>
                <InputField
                  type="number"
                  value={inputField.to}
                  label="To"
                  name="to"
                  onChangeHandler={onChangeHandler}
                  isError={fieldError.to.isError}
                  errorMessage={fieldError.to.errorMessage}
                ></InputField>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6}>
                <InputField
                  type="text"
                  value={inputField.area}
                  label="Working Area"
                  name="area"
                  onChangeHandler={onChangeHandler}
                  isError={fieldError.area.isError}
                  errorMessage={fieldError.area.errorMessage}
                ></InputField>
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingLeft: "1rem" }}>
                <SelectField
                  onChangeHandler={onChangeHandler}
                  name="sectorid"
                  value={inputField.sectorid}
                  isError={fieldError.sectorid.isError}
                  errorMessage={fieldError.sectorid.errorMessage}
                >
                  <option value="">Select sector</option>
                  {sectors &&
                    sectors.map((sector) => {
                      return (
                        <option value={sector.id} key={sector.id}>
                          {sector.name}
                        </option>
                      );
                    })}
                </SelectField>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={6}>
                <SelectField
                  onChangeHandler={onChangeHandler}
                  name="status"
                  value={inputField.status}
                  isError={fieldError.status.isError}
                  errorMessage={fieldError.status.errorMessage}
                >
                  <option value="">Project Status</option>
                  <option value="1">Active</option>
                  <option value="0">Archieved</option>
                </SelectField>
              </Grid>
            </Grid>
            <FileField
              type="file"
              label="Image"
              name="image"
              onChangeHandler={onChangeHandler}
              isError={fieldError.image.isError}
              errorMessage={fieldError.image.errorMessage}
            ></FileField>

            {/* editor */}
            <div style={{ marginBottom: "1.2rem" }}>
              <TextEditor
                initialValue={inputField.description}
                name="description"
                onEditorChange={(content, editor) => {
                  setInputField((prev) => {
                    return {
                      ...prev,
                      description: content,
                    };
                  });
                }}
              />
            </div>
            <div
              style={{
                padding: "1rem 0",
                fontSize: "1.8rem",
                margin: "2rem 0",
                textTransform: "uppercase",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AiOutlineFileSearch
                style={{ marginRight: "1rem", fontSize: "3rem" }}
              />
              <p>Seo Items</p>
            </div>
            <InputField
              type="text"
              value={inputField.meta_keyword}
              label="Project Tag"
              name="meta_keyword"
              onChangeHandler={onChangeHandler}
              isError={fieldError.meta_keyword.isError}
              errorMessage={fieldError.meta_keyword.errorMessage}
            ></InputField>
            <TextAreaField
              type="text"
              value={inputField.meta_description}
              label="Meta Description"
              name="meta_description"
              onChangeHandler={onChangeHandler}
              isError={fieldError.meta_description.isError}
              errorMessage={fieldError.meta_description.errorMessage}
            ></TextAreaField>
            <InputField
              type="text"
              value={inputField.meta_robots}
              label="Meta Robot"
              name="meta_robots"
              onChangeHandler={onChangeHandler}
              isError={fieldError.meta_robots.isError}
              errorMessage={fieldError.meta_robots.errorMessage}
            ></InputField>
            <Button
              onClick={
                urlData.hasOwnProperty("id") ? handleUpdate : handleSubmit
              }
            >
              {urlData.hasOwnProperty("id") ? "Update" : "Add"}
            </Button>
          </>
        )}
      </section>

      {!isUiReady && <LoadingModal />}
    </>
  );
}

export default AddProject;
