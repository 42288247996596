import styled, { css } from "styled-components";
import { redLite, green, primaryColor, greenLite, stdDark } from "../style";
import { FaTimes } from "react-icons/fa";

/*========@Label============ */
const FluidLabel = styled.label`
  position: absolute;
  top: 15px;
  left: 1.4rem;
  font-size: 1.2rem;
  color: ${stdDark};
  transition: 0.3s;
  z-index: -1;
  ${(props) =>
    props.focused &&
    css`
      top: -7px;
      padding: 0 5px;
      color: ${primaryColor};
      background: #fff;
      z-index: 3;
    `}
`;

/*========@Input============ */
const Input = styled.input.attrs((props) => ({
  type: props.type,
  name: props.name,
}))`
  margin-bottom: 1.2rem;
  background: transparent;
  z-index: 2;
  padding: 1.6rem;
  border: 1px solid #eee;
  outline: none;
  width: 100%;
  border-color: ${(props) => (props.value ? primaryColor : "#eee")};
  color: ${stdDark};
  border-radius: 4px;
  transition: 0.3s;
  font-size: 1.6rem;

  &:focus {
    border: 1px solid ${primaryColor};
    outline: none;
  }

  &:focus + label {
    top: -7px;
    padding: 0 5px;
    color: ${primaryColor};
    background: #fff;
    z-index: 3;
  }
`;

export const InputField = ({
  type,
  label,
  value,
  onChangeHandler,
  name,
  isError,
  errorMessage,
}) => {
  return (
    <div className="input-item">
      {isError && (
        <p style={{ color: "red", marginBottom: ".5rem", marginLeft: "1rem" }}>
          {errorMessage}
        </p>
      )}
      <div style={{ position: "relative" }}>
        <Input
          type={type}
          value={value}
          onChange={onChangeHandler}
          name={name}
        ></Input>
        <FluidLabel focused={value}>{label}</FluidLabel>
      </div>
    </div>
  );
};

/*========@textarea============ */
const TextArea = styled.textarea.attrs((props) => ({
  column: props.column,
  name: props.name,
  row: props.row,
}))`
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
  background: transparent;
  z-index: 2;
  padding: 1.6rem;
  border: 1px solid #eee;
  outline: none;
  width: 100%;
  border-color: ${(props) => (props.value ? primaryColor : "#eee")};
  color: ${stdDark};
  border-radius: 4px;
  transition: 0.3s;

  &:focus {
    border: 1px solid ${primaryColor};
    outline: none;
  }

  &:focus + label {
    top: -7px;
    padding: 0 5px;
    color: ${primaryColor};
    background: #fff;
    z-index: 3;
  }
`;
export const TextAreaField = ({
  row,
  column,
  label,
  value,
  onChangeHandler,
  name,
  isError,
  errorMessage,
}) => {
  return (
    <div className="textarea-item">
      {isError && (
        <p style={{ color: "red", marginBottom: ".5rem", marginLeft: "1rem" }}>
          {errorMessage}
        </p>
      )}
      <div style={{ position: "relative" }}>
        <TextArea
          row={row}
          value={value}
          onChange={onChangeHandler}
          name={name}
          column={column}
        ></TextArea>
        <FluidLabel focused={value}>{label}</FluidLabel>
      </div>
    </div>
  );
};

/*========@Select============ */

const Select = styled.select`
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
  background: transparent;
  z-index: 2;
  padding: 1.6rem;
  border: 1px solid #eee;
  outline: none;
  width: 100%;
  /* border-color: ${(props) => (props.value ? primaryColor : "#eee")}; */
  color: ${stdDark};
  border-radius: 4px;
  transition: 0.3s;
`;

export const SelectField = ({
  children,
  name,
  onChangeHandler,
  value,
  isError,
  errorMessage,
}) => {
  return (
    <div className="select-item">
      {isError && (
        <p style={{ color: "red", marginBottom: ".5rem", marginLeft: "1rem" }}>
          {errorMessage}
        </p>
      )}
      <div style={{ position: "relative" }}>
        <Select name={name} onChange={onChangeHandler} value={value}>
          {children}
        </Select>
      </div>
    </div>
  );
};

/*========@Button============ */

export const Button = styled.button`
  background: ${green};
  padding: 0.7rem 1.2rem;
  font-size: 1.4rem;
  border: 0;
  border-radius: 4px;
  color: #fff;

  &:hover {
    outline: none;
    border: none;
  }
`;

/*========@file============ */

export const FileFieldItem = styled.input.attrs((props) => ({
  type: "file",
  name: props.name,
}))`
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
  background: transparent;
  z-index: 2;
  padding: 1.4rem;
  border: 1px solid #eee;
  outline: none;
  width: 100%;
  border-color: ${(props) => (props.value ? primaryColor : "#eee")};
  color: ${stdDark};
  border-radius: 4px;
  transition: 0.3s;
`;
export const FileField = ({ errorMessage, isError, name, onChangeHandler }) => {
  return (
    <>
      {isError && (
        <p style={{ color: "red", marginBottom: ".5rem", marginLeft: "1rem" }}>
          {errorMessage}
        </p>
      )}
      <FileFieldItem name={name} onChange={onChangeHandler}></FileFieldItem>
    </>
  );
};
const AlertContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  align-items: center;
  background: ${(props) => props.bg};
  margin: 1.5rem 0;
  font-size: 1.5rem;
  text-transform: capitalize;
`;

const CancleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
`;

export const Alert = ({
  type,
  children,
  cancleHandler,
  isError,
  errorMessage,
}) => {
  let color;
  if (type === "danger") color = redLite;
  else if (type === "success") color = greenLite;

  return (
    <div className="select-item">
      {isError && (
        <p style={{ color: "red", marginBottom: ".5rem", marginLeft: "1rem" }}>
          {errorMessage}
        </p>
      )}
      <AlertContainer bg={color} className="alert-container">
        {children}
        <CancleButton onClick={cancleHandler}>
          <FaTimes />
        </CancleButton>
      </AlertContainer>
    </div>
  );
};
