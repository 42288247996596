import React from "react";
import { Grid } from "@material-ui/core/";
import { HeaderWraper } from "./Header.Item";

function Header({ logoutHandler }) {
  return (
    <>
      <HeaderWraper container>
        <Grid container item lg={6}>
          1
        </Grid>
        <Grid container item lg={6}>
          <button onClick={logoutHandler}>Logout</button>
        </Grid>
      </HeaderWraper>
    </>
  );
}

export default Header;
