import React from "react";
import { Grid } from "@material-ui/core";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { GrFormView } from "react-icons/gr";
import PropTypes from "prop-types";
import {
  ActivityButton,
  ActivityLink,
  ActivityContainer,
  RowContainer,
} from "./EmployerList.Item";
import { green, red, primaryColor } from "../../style";

function EmployerListRow({ name, email, status, id, deleteButtonHandler }) {
  return (
    <>
      <RowContainer container active={status}>
        <Grid xs={4} item container>
          <p>{name}</p>
        </Grid>
        <Grid xs={4} item container style={{ justifyContent: "center" }}>
          <p style={{ textTransform: "lowercase" }}>{email}</p>
        </Grid>
        <Grid xs={2} item container style={{ justifyContent: "center" }}>
          <p> {status}</p>
        </Grid>
        <Grid xs={2} item container>
          <ActivityContainer>
            <ActivityLink background={green} to={"/add-employer/" + id}>
              <MdModeEdit />
            </ActivityLink>
            <ActivityButton
              background={red}
              onClick={() => {
                deleteButtonHandler(id);
              }}
            >
              <MdDelete />
            </ActivityButton>
            <ActivityLink
              background={primaryColor}
              onClick={() => {
                let url = "http://mkssgaibandha.org/governing-body/";
                var win = window.open(url, "_blank");
                win.focus();
              }}
            >
              <GrFormView />
            </ActivityLink>
          </ActivityContainer>
        </Grid>
      </RowContainer>
    </>
  );
}

EmployerListRow.propTypes = {
  title: PropTypes.string,
  sector: PropTypes.string,
  status: PropTypes.string,
  id: PropTypes.number,
};

EmployerListRow.defaultProps = {
  title: "This is title",
  sector: "education",
  status: 0,
  id: null,
};
export default EmployerListRow;
