import styled from "styled-components";
//import { Button } from "@material-ui/core";
import { primaryColor, primaryLite, stdDark, stdDarkLite } from "../../style";
import { Link } from "react-router-dom";

export const NavigationContainer = styled.div`
  width: 24.5rem;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  background: #f5f5f5;
`;

export const NavigationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  padding: 1rem 1.5rem;
`;

export const MenuContainer = styled.div`
  width: 110%;
  height: 100%;

  overflow: scroll;
  font-size: 1.2rem;
  color: ${stdDark};
`;

export const CloseButton = styled.button`
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  border-color: transparent;
  outline: none;
  background: ${primaryLite};
  color: ${primaryColor};
  line-height: 1.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: #fff;
    background: ${primaryColor};
  }
`;

export const MenuItem = styled(Link)`
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

export const MenuParent = styled.span`
  display: block;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

export const MenuRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10%;
  padding-left: 1rem;
  height: 3.5rem;
  align-items: center;
  &:hover {
    background: ${stdDarkLite};
  }
`;

export const MenuTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MenuArrow = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
`;

export const SubMenu = styled.div`
  margin-left: 1rem;
  overflow: hidden;
  transition: 0.3s;
`;

export const TitleText = styled.span`
  margin-left: 1rem;
`;
