import React, { useState, useEffect } from "react";
import { GrDocumentUpdate, GrUpdate } from "react-icons/gr";
import TextEditor from "../components/Editor/TextEditor";
import Heading from "../Utility/Heading";
import axios from "axios";
import { Alert, Button } from "../Utility/Form";
import { LoadingModal } from "../Utility/Modal";
function AboutUs() {
  const [content, setContent] = useState("");
  const [isUiReady, setIsUiReady] = useState(false);
  const token = window.localStorage.getItem("acc_mk");

  const [alert, setAlert] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const scrollToHead = () => {
    let element = document.querySelector("#root");
    element.scrollIntoView({ behavior: "smooth" });
  };
  const triggerAlert = (message, type) => {
    scrollToHead();
    setAlert((prev) => {
      return {
        message,
        type,
        isOpen: true,
      };
    });
  };
  const submitHandler = (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/aboutus/update`, {
        token,
        content,
      })
      .then((response) => {
        triggerAlert("Successfully Updated", "success");
      })
      .catch((error) => {
        triggerAlert("Something Wrong!! Talk to Rifat", "danger");
      });
  };
  useEffect(() => {
    let data = {
      params: {
        token,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/aboutus/get`, data)
      .then((response) => {
        setContent(response.data);
        setIsUiReady(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <Heading icon={<GrDocumentUpdate />}>About Us</Heading>
      {alert.isOpen && (
        <Alert
          type={alert.type}
          cancleHandler={() => {
            setAlert((prev) => {
              return { ...prev, isOpen: false };
            });
          }}
        >
          {alert.message}
        </Alert>
      )}
      <div style={{ position: "relative" }}>
        {isUiReady && (
          <>
            <div style={{ marginBottom: "1.2rem", marginTop: "2rem" }}>
              <TextEditor
                name="description"
                initialValue={content}
                onEditorChange={(content, editor) => {
                  setContent(content);
                }}
              />
            </div>
            <Button style={{ marginBottom: "5rem" }} onClick={submitHandler}>
              Update
            </Button>
          </>
        )}

        {!isUiReady && <LoadingModal />}
      </div>
    </>
  );
}

export default AboutUs;
