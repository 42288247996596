import React, { useState, useEffect } from "react";
import axios from "axios";
import Dashboard from "./Dashboard";
import AddProject from "./AddProject";
import ProjectList from "./ProjectList";
import AddNews from "./AddNews";
import NewsList from "./NewsList";
import { Route, Redirect } from "react-router-dom";
import { Header, Navigation } from "../components";
import { Container, MainBody } from "../style";
import styled from "styled-components";
import Partner from "./Partner";
import ProjectOverview from "./ProjectOverview";
import AboutUs from "./AboutUs";
import AddEmployer from "./AddEmployer";
import EmployerList from "./EmployerList";

const MainSection = styled.section`
  margin-left: 24.5rem;
`;
function Main({ children }) {
  const headers = {
    "Content-Type": "Application/Json",
    Accept: "Application/Json",
  };
  const [isAuth, setIsAuth] = useState(true);
  const PrivateRoute = ({ children, ...rest }) => {
    return (
      <Route {...rest}>{isAuth ? children : <Redirect to="/login" />}</Route>
    );
  };
  let token = window.localStorage.getItem("acc_mk");
  const logoutHandler = (e) => {
    e.preventDefault();
    let content = {
      params: {
        token: token,
      },
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/logout`, { token })
      .then((response) => {
        window.localStorage.removeItem("acc_mk");
        setIsAuth(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/me`, { token: token })
      .then((response) => {
        setIsAuth(true);
      })
      .catch((err) => {
        setIsAuth(false);
      });

    // return () => {
    //   unmounted = true;
    // };
  });

  return (
    <main>
      {!isAuth && (
        <Route>
          <Redirect to="/login" />
        </Route>
      )}
      <Container>
        <Navigation />
        <MainSection style={{ position: "relative" }}>
          <Header logoutHandler={logoutHandler} />
          <MainBody>
            <PrivateRoute path="/dashboard" exact>
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/add-project" exact>
              <AddProject />
            </PrivateRoute>
            <PrivateRoute path="/project-list" exact>
              <ProjectList />
            </PrivateRoute>
            <PrivateRoute
              path="/add-project/:id"
              children={<AddProject />}
              exact
            ></PrivateRoute>
            <PrivateRoute path="/add-news" exact>
              <AddNews />
            </PrivateRoute>
            <PrivateRoute path="/news-list" exact>
              <NewsList />
            </PrivateRoute>
            <PrivateRoute
              path="/add-news/:id"
              children={<AddNews />}
              exact
            ></PrivateRoute>
            <PrivateRoute
              path="/partner"
              children={<Partner />}
              exact
            ></PrivateRoute>
            <PrivateRoute
              path="/project-overview"
              children={<ProjectOverview />}
              exact
            ></PrivateRoute>
            <PrivateRoute
              path="/aboutus"
              children={<AboutUs />}
              exact
            ></PrivateRoute>
            <PrivateRoute
              path="/add-employer"
              children={<AddEmployer />}
              exact
            ></PrivateRoute>
            <PrivateRoute
              path="/add-employer/:id"
              children={<AddEmployer />}
              exact
            ></PrivateRoute>
            <PrivateRoute
              path="/employer-list"
              children={<EmployerList />}
              exact
            ></PrivateRoute>
          </MainBody>
        </MainSection>
      </Container>
    </main>
  );
}

export default Main;
