import React, { useState } from "react";

import {
  MenuItem,
  MenuParent,
  MenuTitle,
  MenuRow,
  MenuArrow,
  TitleText,
  SubMenu,
} from "./Navigation.Item";

import { IoIosArrowDown } from "react-icons/io";

function MenuItemContainer({ children, title, menuIcon, to, type }) {
  const [subMenuState, setSubMenuState] = useState(false);

  const clickHandler = (e) => {
    setSubMenuState(!subMenuState);
  };

  return (
    <>
      {type === "parent" ? (
        <MenuParent className="menu-parent">
          <MenuRow onClick={clickHandler}>
            <MenuTitle>
              {menuIcon}
              <TitleText>{title}</TitleText>
            </MenuTitle>

            {children && (
              <MenuArrow>
                <IoIosArrowDown />
              </MenuArrow>
            )}
          </MenuRow>
          {subMenuState && <SubMenu>{children}</SubMenu>}
        </MenuParent>
      ) : (
        <MenuItem to={to} className="menu-parent">
          <MenuRow onClick={clickHandler}>
            <MenuTitle>
              {menuIcon}
              <TitleText>{title}</TitleText>
            </MenuTitle>

            {children && (
              <MenuArrow>
                <IoIosArrowDown />
              </MenuArrow>
            )}
          </MenuRow>
          {subMenuState && <SubMenu>{children}</SubMenu>}
        </MenuItem>
      )}
    </>
  );
}

export default MenuItemContainer;
