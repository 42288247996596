import styled from "styled-components";

export const Container = styled.div`
  max-width: 1368px;
  margin: 0 auto;
`;

export const primaryColor = "#2196F3";
export const primaryLite = "#DFEBF5";
export const stdDark = "#757575";
export const stdDarkLite = "#EAEAEA";
export const green = "#4CAF69";
export const greenLite = "#EDF7ED";
export const red = "#F44336";
export const redLite = "#FEECEB";

export const MainBody = styled.main`
  padding: 0 1.5rem;
`;
