import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ItemCard from "../Utility/ItemCard";
import { green } from "../style";
import { FaRegNewspaper } from "react-icons/fa";

import { NewsListHeading } from "../components/News/NewsList.Item";
import NewsListRow from "../components/News/NewsListRow";
import { ConfirmModal, LoadingModal } from "../Utility/Modal";
import axios from "axios";

function NewsList() {
  /*token */
  const token = window.localStorage.getItem("acc_mk");
  const headers = {};
  const [modal, setModal] = useState({
    confirmModal: false,
    loading: true,
  });

  const [news, setNews] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const hideModal = (name) => {
    setModal((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
  };

  const showModal = (name) => {
    setModal((prev) => {
      return {
        ...prev,
        [name]: true,
      };
    });
  };

  const deleteButtonHandler = (id) => {
    setDeleteId(id);
    showModal("confirmModal");
  };

  const confirmDelete = () => {
    hideModal("confirmModal");
    showModal("loading");
    let content = {
      params: {
        token,
      },
      headers: {
        ...headers,
      },
    };
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/news/delete/` + deleteId,
        content
      )
      .then((response) => {
        console.log(response);
        hideModal("loading");
        if (response.data === 1) {
          setNews((prev) => {
            return prev.filter((news) => news.id != deleteId);
          });
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    let content = {
      params: {
        token,
      },

      headers: { ...headers },
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/news/get/list`, content)
      .then((response) => {
        setNews(response.data);
        hideModal("loading");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <section className="news-list">
        <Grid container style={{ marginTop: "3rem" }}>
          <Grid
            conainer
            item
            xs={12}
            md={4}
            style={{ marginBottom: "3rem", padding: "0 1rem" }}
          >
            <ItemCard
              value={news.length}
              title="Total News"
              color="#fff"
              background={green}
              icon={<FaRegNewspaper />}
            ></ItemCard>
          </Grid>
        </Grid>

        {/* list header */}
        <NewsListHeading container style={{ marginTop: "" }}>
          <Grid xs={8} item container>
            Title
          </Grid>
          <Grid xs={2} item container style={{ background: "#888" }}>
            Category
          </Grid>

          <Grid xs={2} item container>
            Activity
          </Grid>
        </NewsListHeading>
        {news.map((item) => {
          return (
            <NewsListRow
              title={item.title}
              category={item.category}
              id={item.id}
              key={item.id}
              deleteButtonHandler={deleteButtonHandler}
            />
          );
        })}
      </section>

      {modal.confirmModal && (
        <ConfirmModal
          message="Confirm Delete?"
          cancleHandler={() => {
            hideModal("confirmModal");
          }}
          confirmHandler={confirmDelete}
        />
      )}

      {modal.loading && <LoadingModal />}
    </>
  );
}

export default NewsList;
