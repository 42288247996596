import { Editor } from "@tinymce/tinymce-react";

import React, { useState, useEffect } from "react";

function TextEditor({ initialValue, onEditorChange, name }) {
  const [content, setContent] = useState();

  useEffect(() => {
    setContent(initialValue);
  }, [initialValue]);
  return (
    <div name={name} style={{ border: "1px solid transparent" }}>
      <Editor
        apiKey="fapb9gllwjz7ohwj6qzlt2myr4t6khp7ya6i528lt3ptvu5g"
        initialValue={content}
        init={{
          height: 500,
          menubar: true,
          selector: "textarea",
          plugins: [
            "table",
            "fullscreen",
            "code",
            "codesample",
            "emoticons",
            "visualblocks",
            "hr",
            "pagebreak",
            "advlist autolink lists advlist link image imagetools",
            "charmap print preview anchor help",
            "searchreplace visualblocks code",
            "insertdatetime media table paste wordcount ",
          ],
          toolbar: `undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | image \
            bullist numlist outdent indent | help code codesample emoticons pagebreak visualblocks hr figure removeformat `,

          fullscreen_native: true,
          setup: function (editor) {
            editor.ui.registry.addButton("figure", {
              text: "Table Figure",
              onAction: function (_) {
                let selectedContent = editor.selection.getNode();

                let current = selectedContent;
                //console.log(current.parentNode.nodeName);
                while (
                  current.nodeName !== "TABLE" &&
                  current.nodeName !== "BODY"
                ) {
                  current = current.parentNode;
                }

                if (current.nodeName === "TABLE") {
                  if (current.parentNode.nodeName !== "FIGURE") {
                    editor.selection.select(current);
                    let figure = document.createElement("figure");
                    figure.appendChild(current);
                    editor.insertContent(
                      `<figure style="width:100%">${figure.innerHTML}</figure>`
                    );
                  }
                }

                // editor.selection.select(selectedContent);

                // let figure = document.createElement("figure");
                // figure.appendChild(selectedContent);
                // editor.insertContent(
                //   `<figure style="width:100%">${figure.innerHTML}</figure>`
                // );
              },
            });
          },
        }}
        onEditorChange={onEditorChange}
      />
    </div>
  );
}

export default TextEditor;
