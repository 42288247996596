import styled from "styled-components";

import React from "react";
import { primaryColor } from "../style";

const ItemCardWraper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-radius: 8px;
  background: ${(props) => props.background || primaryColor};
  color: ${(props) => props.color || "#333"};
`;

const CardValue = styled.div`
  font-size: 3rem;
  margin-bottom: 0.3rem;
  font-weight: bold;
`;

const CardTitle = styled.div`
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: bold;
`;

const CardIcon = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  color: ${(props) => props.color || "#333"};

  & path {
    fill: ${(props) => props.color || "#333"};
  }
`;
function ItemCard({ value, icon, title, background, color }) {
  return (
    <>
      <ItemCardWraper background={background} color={color}>
        <div>
          <CardValue>{value}</CardValue>
          <CardTitle>{title}</CardTitle>
        </div>
        <CardIcon color={color}>{icon}</CardIcon>
      </ItemCardWraper>
    </>
  );
}

export default ItemCard;
