import React from "react";
import Heading from "../Utility/Heading";
import { RiDashboardFill } from "react-icons/ri";

function Home() {
  return (
    <section>
      <Heading icon={<RiDashboardFill />}>Dashboard</Heading>
    </section>
  );
}

export default Home;
