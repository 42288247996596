import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const HeaderWraper = styled(Grid)`
  width: 100%;
  height: 62px;
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 0;
  padding: 1rem 1.5rem;
  z-index: 100;
  background: #fff;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 12%);
`;
