import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/Login";
import Main from "./pages/Main";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route children={<Login />} path="/" exact />
          <Route children={<Login />} path="/login" exact />
          <Main></Main>
        </Switch>
      </Router>
    </>
  );
}

export default App;
