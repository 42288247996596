import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import styled, { css } from "styled-components";
import { FaUserAlt } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import axios from "axios";
//styled elem
export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || "default"};
  align-items: ${({ align }) => align || "default"};
  margin: ${({ margin }) => margin || "0"};
`;
export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;

  @media screen and (max-width: 1200px) {
    max-width: 960px;
    padding: 0 0.8rem;
  }
  @media screen and (max-width: 960px) {
    max-width: 768px;
    padding: 0 0.8rem;
  }

  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding: 0 0.5rem;
  }
`;
export const LoginContainer = styled.section`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginBox = styled.div`
  width: 460px;
  padding: 4rem 3rem;
  font-size: 1.6rem;
  border: 1px solid #dfdfdf;
  height: auto;

  @media screen and (max-width: 560px) {
    width: 100%;
  }
`;
export const LoginHeader = styled.div`
  text-align: center;
  padding: 2rem;
`;

export const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 1.2rem;
  position: relative;
`;

//when input item is focused
//focus effect on label
const focusValue = css`
  top: -1rem;
  color: #333;
  background-color: #fff;
  font-size: 1.2rem;
  z-index: 2;
`;

export const InputLabel = styled.label`
  position: absolute;
  color: #333;
  left: 1rem;
  white-space: nowrap;
  padding: 0 1rem;
  transition: 0.2s;
  text-overflow: ellipsis;
  top: 1rem;
  z-index: -1;

  ${({ hasValue }) => hasValue && focusValue}
`;

export const InputItem = styled.input`
  width: 100%;
  padding: 1.5rem;
  border: 1px solid #999;
  margin-bottom: 1rem;
  background-color: transparent;
  z-index: 1;
  &:focus {
    outline: 0;
    border-color: pink;
  }

  &:focus + ${InputLabel}, &:active + ${InputLabel} {
    ${focusValue}
  }
`;

export const LoginButton = styled.button`
  background: #d8315b;
  padding: 1rem 4rem;
  border: 0;
  border-radius: 0.5rem;
  color: #fff;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  font-size: 1.6rem;
`;

export const PasswordEye = styled.span`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

function Login() {
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValidate, setIsValidate] = useState(false);
  let token = window.localStorage.getItem("acc_mk");

  const headers = {
    "Content-Type": "application/json",
  };
  const submitHandler = (e) => {
    e.preventDefault();
    let data = {
      email,
      password,
    };
    let content = {
      header: {
        ...headers,
      },
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/login`, data, content)
      .then((response) => {
        if (response.data.access_token) {
          window.localStorage.setItem("acc_mk", response.data.access_token);
          setIsValidate(true);
        }

        //return (window.location = "/dashboard");
      })
      .catch((err) => {
        console.log("something wrong");
        console.log(err);
      });
  };
  const handleInput = (e) => {
    const name = e.target.name;
    if (name == "email") {
      setEmail(e.target.value);
    } else if (name == "password") {
      setPassword(e.target.value);
    }
  };

  useEffect(() => {
    let token = window.localStorage.getItem("acc_mk");

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/auth/me`, { token: token })
      .then((response) => {
        setIsValidate(true);
      })
      .catch((err) => {
        //  console.log(err)
      });

    // return () => {
    //   unmounted = true;
    // };
  }, []);
  return (
    <>
      <Container>
        {isValidate && (
          <Route>
            <Redirect to="/dashboard" />
          </Route>
        )}
        <LoginContainer>
          <LoginBox>
            <LoginHeader>
              <h1>Login Page</h1>
            </LoginHeader>
            <form>
              <InputContainer>
                <InputItem
                  type="email"
                  value={email}
                  name="email"
                  onChange={handleInput}
                />
                <InputLabel hasValue={email != ""}>
                  <FaUserAlt
                    style={{
                      color: "#444",
                      marginRight: "1rem",
                    }}
                  />
                  Username
                </InputLabel>
              </InputContainer>

              <InputContainer>
                <InputItem
                  type={showPass ? "text" : "password"}
                  value={password}
                  name="password"
                  onChange={handleInput}
                />
                <InputLabel hasValue={password != ""}>
                  <RiLockPasswordFill
                    style={{
                      color: "#444",
                      marginRight: ".4rem",
                    }}
                  />
                  Password
                </InputLabel>

                <PasswordEye onClick={() => setShowPass(!showPass)}>
                  {showPass ? <BsEyeSlashFill /> : <BsEyeFill />}
                </PasswordEye>
              </InputContainer>

              <FlexContainer justify="center" align="center" margin="1.5rem">
                <LoginButton onClick={submitHandler}>Login</LoginButton>
              </FlexContainer>
            </form>
          </LoginBox>
        </LoginContainer>
      </Container>
    </>
  );
}

export default Login;
