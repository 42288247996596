import React, { useEffect, useState } from "react";
import { InputField, Button, FileField, Alert } from "../Utility/Form";
import Heading from "../Utility/Heading";
import { green, primaryColor } from "../style";
import { AiOutlinePlus } from "react-icons/ai";

import axios from "axios";
import { useParams } from "react-router-dom";
import TextEditor from "../components/Editor/TextEditor";
import { LoadingModal } from "../Utility/Modal";

function AddEmployer() {
  const urlData = useParams();
  const token = window.localStorage.getItem("acc_mk");
  const headers = {};
  const [pageInfo, setPageInfo] = useState({
    title: "Add Employer",
  });

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    message: "",
  });
  const [isUiReady, setIsUiReady] = useState(false);
  const [inputField, setInputField] = useState({
    name: "",
    email: "",
    description: "",
    priority: "",
    status: "",
    image: "",
  });
  const [fieldError, setFieldError] = useState({
    name: {
      isError: false,
      errorMessage: "",
      isRequired: true,
      length: 50,
    },
    email: {
      isError: false,
      errorMessage: "",
      isRequired: true,
      length: 50,
    },
    description: {
      isError: false,
      errorMessage: "",
      isRequired: true,
      length: 1000,
    },

    image: {
      isError: false,
      errorMessage: "",
      isRequired: true,
    },
    priority: {
      isError: false,
      errorMessage: "",
      isRequired: true,
      length: 10,
    },

    status: {
      isError: false,
      errorMessage: "",
      isRequired: true,
      length: 30,
    },
  });

  const onChangeHandler = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    /* handle image file differently */
    if (name === "image") {
      value = e.target.files[0];
    }
    /* updating the state */
    setInputField((prev) => {
      return { ...prev, [name]: value };
    });
  };
  /* top alert box */
  const triggerAlert = (message, type) => {
    scrollToHead();

    setAlert({
      isOpen: true,
      message,
      type,
    });
  };
  /* check every field before submition*/
  const fieldValidation = () => {
    let isValid = true;
    let fieldErrorClone = { ...fieldError };

    for (let key in fieldErrorClone) {
      let htmlElem = document.querySelector(`[name~=${key}]`);

      if (fieldErrorClone[key].isRequired && inputField[key] === "") {
        isValid = false;
        fieldErrorClone[key].isError = true;
        fieldErrorClone[key].errorMessage = "* Field Should Not be Empty";
        if (htmlElem) {
          htmlElem.style.borderColor = "red";
        }
      } else if (
        inputField[key] !== "" &&
        fieldErrorClone[key] != null &&
        inputField[key] != null &&
        fieldErrorClone[key].length < inputField[key].length
      ) {
        isValid = false;
        fieldErrorClone[key].isError = true;
        fieldErrorClone[
          key
        ].errorMessage = `length should not exceed ${fieldErrorClone[key].length}`;
        if (htmlElem) {
          htmlElem.style.borderColor = "red";
        }
      } else {
        fieldErrorClone[key].isError = false;
        if (htmlElem) {
          htmlElem.style.borderColor = "green";
        }
      }
    }
    setFieldError(fieldErrorClone);
    return isValid;
  };

  const isEveryThingOk = () => {
    if (fieldValidation()) return true;
    else {
      console.log(fieldError);
      triggerAlert("Fix The error", "danger");
      return false;
    }
  };

  const scrollToHead = () => {
    let element = document.querySelector("#root");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = () => {
    /* check every required field */
    if (!isEveryThingOk()) {
      return;
    }

    setIsUiReady(false);
    /* creating form data from state */
    let data = new FormData();
    for (let key in inputField) {
      data.append(key, inputField[key]);
    }
    data.append("token", token);

    /* sending request to server */
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/employer/insert`, data, {
        headers: {
          "Content-type": "multipart/form-data",
          ...headers,
        },
      })
      .then((response) => {
        handleRespone(response);
        setIsUiReady(true);
        console.log(response);
      })
      .catch((error) => {
        setIsUiReady(true);

        triggerAlert("Something wrong, Call Rifat !!", "danger");

        console.log(error);
      });
  };
  const handleUpdate = () => {
    /* check every required field */
    if (!isEveryThingOk()) {
      return;
    }

    /* creating form data from state */
    let data = new FormData();

    for (let key in inputField) {
      data.append(key, inputField[key]);
    }
    data.append("token", token);
    /* sending request to server */

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/employer/update/` + urlData.id,
        data,
        {
          headers: {
            "Content-type": "multipart/form-data",
            ...headers,
          },
        }
      )
      .then((response) => {
        handleUpdateResponse(response);
      })
      .catch((error) => {});
  };
  const handleUpdateResponse = (response) => {
    if (response.data === 1) {
      for (const key in inputField) {
        let target = document.querySelector("[name~=" + key);
        if (key == "image") {
          target.value = null;
        }
        if (target) {
          target.style.borderColor = "";
        }
      }
    }

    if (response.data === 1) {
      triggerAlert("Successfully Updated", "success");
    } else {
      triggerAlert("Reload The page and try again", "danger");
    }
  };

  const handleRespone = (response) => {
    if (response.data === 1) {
      for (const key in inputField) {
        let target = document.querySelector("[name~=" + key);

        if (target) {
          target.style.borderColor = "";
          if (key == "image") {
            target.value = null;
          }
        }
      }
      setInputField({
        name: "",
        email: "",
        description: "",
        priority: "",
        status: "",
        image: "",
      });
    }
    if (response.data === 1) {
      triggerAlert("Successfully Added", "success");
    } else {
      triggerAlert("Reload The page and try again", "danger");
    }
  };

  useEffect(() => {
    if (urlData.hasOwnProperty("id")) {
      let content = {
        params: {
          token,
        },
        headers: { ...headers },
      };
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/employer/get/` + urlData.id,
          content
        )
        .then((response) => {
          setInputField((prev) => {
            let newData = {};
            for (const key in prev) {
              newData[key] =
                response.data[key] == null ? "" : response.data[key];
            }
            newData["image"] = 0;
            setIsUiReady(true);
            return newData;
          });
        })
        .catch((error) => {
          console.log(error);
        });
      setPageInfo((prev) => {
        return {
          ...prev,
          title: "Update Employer",
        };
      });
    } else {
      setPageInfo((prev) => {
        return {
          ...prev,
          title: "Add Employer",
        };
      });

      setInputField((prev) => {
        let newData = {};
        for (const key in prev) {
          newData[key] = "";
        }

        return newData;
      });

      setAlert((prev) => {
        return {
          ...prev,
          isOpen: false,
        };
      });
    }
  }, [urlData]);

  useEffect(() => {
    setTimeout(() => {
      setIsUiReady(true);
    }, 500);
  }, []);
  return (
    <>
      <section style={{ marginBottom: "4rem" }}>
        <Heading
          icon={<AiOutlinePlus />}
          background={urlData.hasOwnProperty("id") ? green : primaryColor}
        >
          {pageInfo.title}
        </Heading>
        {/* failed alert */}
        {alert.isOpen && (
          <Alert
            type={alert.type}
            cancleHandler={() => {
              setAlert((prev) => {
                return { ...prev, isOpen: false };
              });
            }}
          >
            {alert.message}
          </Alert>
        )}
        {isUiReady && (
          <>
            <InputField
              type="text"
              value={inputField.name}
              label="Name"
              name="name"
              onChangeHandler={onChangeHandler}
              isError={fieldError.name.isError}
              errorMessage={fieldError.name.errorMessage}
            ></InputField>
            <InputField
              type="email"
              value={inputField.email}
              label="Email"
              name="email"
              onChangeHandler={onChangeHandler}
              isError={fieldError.email.isError}
              errorMessage={fieldError.email.errorMessage}
            ></InputField>
            <InputField
              type="text"
              value={inputField.status}
              label="Employer Status"
              name="status"
              onChangeHandler={onChangeHandler}
              isError={fieldError.status.isError}
              errorMessage={fieldError.status.errorMessage}
            ></InputField>
            <InputField
              type="number"
              value={inputField.priority}
              label="View Priority"
              name="priority"
              onChangeHandler={onChangeHandler}
              isError={fieldError.priority.isError}
              errorMessage={fieldError.priority.errorMessage}
            ></InputField>

            <FileField
              type="file"
              label="Image"
              name="image"
              onChangeHandler={onChangeHandler}
              isError={fieldError.image.isError}
              errorMessage={fieldError.image.errorMessage}
            ></FileField>

            {/*  editor */}
            <div style={{ marginBottom: "1.2rem" }}>
              {fieldError["description"].isError && (
                <p
                  style={{
                    color: "red",
                    marginBottom: ".5rem",
                    marginLeft: "1rem",
                  }}
                >
                  {fieldError["description"].errorMessage}
                </p>
              )}
              <TextEditor
                name="description"
                initialValue={inputField.description}
                onEditorChange={(content, editor) => {
                  setInputField((prev) => {
                    return {
                      ...prev,
                      description: content,
                    };
                  });
                }}
              />
            </div>

            <Button
              onClick={
                urlData.hasOwnProperty("id") ? handleUpdate : handleSubmit
              }
            >
              {urlData.hasOwnProperty("id") ? "Update" : "Add"}
            </Button>
          </>
        )}
        {!isUiReady && <LoadingModal />}
      </section>
    </>
  );
}

export default AddEmployer;
