import { Grid } from "@material-ui/core";
import React from "react";
import { MdModeEdit, MdDelete } from "react-icons/md";
import { GrFormView } from "react-icons/gr";
import PropTypes from "prop-types";
import {
  ActivityButton,
  ActivityLink,
  ActivityContainer,
  RowContainer,
} from "./NewsList.Item";
import { green, red, primaryColor } from "../../style";

function NewsListRow({ title, category, id, deleteButtonHandler }) {
  return (
    <>
      <RowContainer container>
        <Grid xs={8} item container>
          <p>{title}</p>
        </Grid>
        <Grid xs={2} item container style={{ justifyContent: "center" }}>
          <p>{category}</p>
        </Grid>

        <Grid xs={2} item container>
          <ActivityContainer>
            <ActivityLink background={green} to={"/add-news/" + id}>
              <MdModeEdit />
            </ActivityLink>
            <ActivityButton
              background={red}
              onClick={() => {
                deleteButtonHandler(id);
              }}
            >
              <MdDelete />
            </ActivityButton>
            <ActivityLink
              background={primaryColor}
              onClick={() => {
                let url = "http://mkssgaibandha.org/news/" + title;
                var win = window.open(url, "_blank");
                win.focus();
              }}
            >
              <GrFormView />
            </ActivityLink>
          </ActivityContainer>
        </Grid>
      </RowContainer>
    </>
  );
}

NewsListRow.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  id: PropTypes.number,
};

NewsListRow.defaultProps = {
  title: "This is title",
  category: "education",
  id: null,
};
export default NewsListRow;
