import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import ItemCard from "../Utility/ItemCard";
import { green } from "../style";
import { FaRegNewspaper } from "react-icons/fa";
import { NewsListHeading } from "../components/News/NewsList.Item";
import { ConfirmModal, LoadingModal } from "../Utility/Modal";
import axios from "axios";
import EmployerListRow from "../components/EmployerList/EmployerListRow";

function EmployerList() {
  /*token */
  const token = window.localStorage.getItem("acc_mk");

  const [modal, setModal] = useState({
    confirmModal: false,
    loading: true,
  });

  const [employer, setEmployer] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const hideModal = (name) => {
    setModal((prev) => {
      return {
        ...prev,
        [name]: false,
      };
    });
  };

  const showModal = (name) => {
    setModal((prev) => {
      return {
        ...prev,
        [name]: true,
      };
    });
  };

  const deleteButtonHandler = (id) => {
    setDeleteId(id);
    showModal("confirmModal");
  };

  const confirmDelete = () => {
    hideModal("confirmModal");
    showModal("loading");
    let content = {
      params: {
        token,
      },
    };
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/employer/delete/` + deleteId,
        content
      )
      .then((response) => {
        console.log(response);
        hideModal("loading");
        if (response.data === 1) {
          setEmployer((prev) => {
            return prev.filter((employer) => employer.id != deleteId);
          });
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    let content = {
      params: {
        token,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/employer/getlist`, content)
      .then((response) => {
        setEmployer(response.data);
        hideModal("loading");
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <section className="news-list">
        <Grid container style={{ marginTop: "3rem" }}>
          <Grid
            conainer
            item
            xs={12}
            md={4}
            style={{ marginBottom: "3rem", padding: "0 1rem" }}
          >
            <ItemCard
              value={employer.length}
              title="Total Employer"
              color="#fff"
              background={green}
              icon={<FaRegNewspaper />}
            ></ItemCard>
          </Grid>
        </Grid>

        {/* list header */}
        <NewsListHeading container style={{ marginTop: "" }}>
          <Grid xs={4} item container>
            Name
          </Grid>
          <Grid xs={4} item container style={{ background: "#888" }}>
            Email
          </Grid>
          <Grid xs={2} item container>
            Status
          </Grid>
          <Grid xs={2} item container style={{ background: "#888" }}>
            Activity
          </Grid>
        </NewsListHeading>
        {!modal.loading && (
          <>
            {employer.map((item) => {
              return (
                <EmployerListRow
                  name={item.name}
                  email={item.email}
                  status={item.status}
                  id={item.id}
                  key={item.id}
                  deleteButtonHandler={deleteButtonHandler}
                />
              );
            })}
          </>
        )}
        {modal.loading && <LoadingModal />}
      </section>

      {modal.confirmModal && (
        <ConfirmModal
          message="Confirm Delete?"
          cancleHandler={() => {
            hideModal("confirmModal");
          }}
          confirmHandler={confirmDelete}
        />
      )}
    </>
  );
}

export default EmployerList;
