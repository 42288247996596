import React, { useState, useEffect } from "react";
import {
  InputField,
  SelectField,
  Button,
  FileField,
  Alert,
  TextAreaField,
} from "../Utility/Form";
import Heading from "../Utility/Heading";
import { green, primaryColor } from "../style";
import { AiOutlinePlus } from "react-icons/ai";
import { Grid } from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import TextEditor from "../components/Editor/TextEditor";
import { AiOutlineFileSearch } from "react-icons/ai";
import { LoadingModal } from "../Utility/Modal";

function AddNews() {
  /*token */
  const token = window.localStorage.getItem("acc_mk");

  const headers = {};
  /*url data */
  const urlData = useParams();

  const [pageInfo, setPageInfo] = useState({
    title: "Add News",
  });

  const [isUiReady, setIsUiReady] = useState(false);
  /* form data */

  const [inputField, setInputField] = useState({
    title: "",
    newscategoryid: "0",
    description: "",
    imagecaption: "",
    image: "",
    meta_description: "",
    meta_keyword: "",
    meta_robots: "",
  });
  const [fieldError, setFieldError] = useState({
    title: {
      isError: false,
      errorMessage: "",
      isRequired: true,
    },
    imagecaption: {
      isError: false,
      errorMessage: "",
      isRequired: true,
    },
    newscategoryid: {
      isError: false,
      errorMessage: "",
      isRequired: true,
    },

    image: {
      isError: false,
      errorMessage: "",
      isRequired: true,
    },
    meta_description: {
      isError: false,
      errorMessage: "",
      isRequired: false,
    },
    meta_keyword: {
      isError: false,
      errorMessage: "",
      isRequired: false,
    },
    meta_robots: {
      isError: false,
      errorMessage: "",
      isRequired: false,
    },
    description: {
      isError: false,
      errorMessage: "",
      isRequired: true,
    },
  });
  const [category, setCategory] = useState();
  // const [valid, setValid] = useState(false);
  // const [apiStatus, setApiStatus] = useState(false);

  const [alert, setAlert] = useState({
    isOpen: false,
    type: "",
    message: "",
  });

  const onChangeHandler = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    /* handle image file differently */
    if (name === "image") {
      value = e.target.files[0];
    }
    /* updating the state */
    setInputField((prev) => {
      return { ...prev, [name]: value };
    });
  };

  /* handle response -> after submiting the form */

  const handleRespone = (response) => {
    scrollToHead();
    if (response.data === 1) {
      for (const key in inputField) {
        let target = document.querySelector("[name~=" + key);

        if (target) {
          target.style.borderColor = "";
          if (key == "image") {
            target.value = null;
          }
        }
      }
      setInputField({
        title: "",
        newscategoryid: "0",
        description: "",
        imagecaption: "",
        image: "",
        meta_description: "",
        meta_keyword: "",
        meta_robots: "",
      });
    }
    if (response.data === 1) {
      triggerAlert("Successfully Added", "success");
    } else if (response.data === -3) {
      if (document.querySelector("[name~=title]"))
        document.querySelector("[name~=title]").style.borderColor = "red";
      triggerAlert("Title should be unique", "danger");
    } else {
      triggerAlert("Reload The page and try again", "danger");
    }
  };

  const handleUpdateResponse = (response) => {
    if (response.data === 1) {
      for (const key in inputField) {
        let target = document.querySelector("[name~=" + key);
        if (key == "image") {
          target.value = null;
        }
        if (target) {
          target.style.borderColor = "";
        }
      }
    }

    if (response.data === 1) {
      triggerAlert("Successfully Updated", "success");
    } else if (response.data === -3) {
      document.querySelector("[name~=title]").style.borderColor = "red";
      triggerAlert("Title should be unique", "danger");
    } else {
      triggerAlert("Reload The page and try again", "danger");
    }
  };

  /* top alert box */
  const triggerAlert = (message, type) => {
    scrollToHead();

    setAlert({
      isOpen: true,
      message,
      type,
    });
  };
  /* check every field before submition*/
  const fieldValidation = () => {
    let isValid = true;
    let fieldErrorClone = { ...fieldError };

    for (let key in fieldErrorClone) {
      let htmlElem = document.querySelector(`[name~=${key}]`);

      if (fieldErrorClone[key].isRequired && inputField[key] === "") {
        console.log(key);
        console.log(inputField);
        isValid = false;
        fieldErrorClone[key].isError = true;
        fieldErrorClone[key].errorMessage = "* Field Should Not be Empty";
        if (htmlElem) {
          htmlElem.style.borderColor = "red";
        }
      } else if (
        inputField[key] !== "" &&
        fieldErrorClone[key] != null &&
        inputField[key] != null &&
        fieldErrorClone[key].length < inputField[key].length
      ) {
        isValid = false;
        fieldErrorClone[key].isError = true;
        fieldErrorClone[
          key
        ].errorMessage = `length should not exceed ${fieldErrorClone[key].length}`;
        if (htmlElem) {
          htmlElem.style.borderColor = "red";
        }
      } else {
        fieldErrorClone[key].isError = false;
        if (htmlElem) {
          htmlElem.style.borderColor = "green";
        }
      }
    }
    setFieldError(fieldErrorClone);
    return isValid;
  };

  /* check every required field */
  const isEveryThingOk = () => {
    if (fieldValidation()) return true;
    else {
      triggerAlert("Fix The error", "danger");
      return false;
    }
  };

  const scrollToHead = () => {
    let element = document.querySelector("#root");
    element.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = () => {
    /* check every required field */
    if (!isEveryThingOk()) {
      return;
    }

    setIsUiReady(false);
    /* creating form data from state */
    let data = new FormData();
    for (let key in inputField) {
      data.append(key, inputField[key]);
    }
    data.append("token", token);
    console.log(inputField);
    /* sending request to server */
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/news/insert`, data, {
        headers: {
          "Content-type": "multipart/form-data",
          ...headers,
        },
      })
      .then((response) => {
        handleRespone(response);
        setIsUiReady(true);
        console.log(response);
      })
      .catch((error) => {
        setIsUiReady(true);
        setAlert((prev) => {
          scrollToHead();
          return {
            type: "danger",
            message: "Internal server error",
            isOpen: true,
          };
        });
        console.log(error);
      });
  };

  const handleUpdate = () => {
    /* check every required field */
    if (!isEveryThingOk()) {
      return;
    }

    /* creating form data from state */
    let data = new FormData();

    for (let key in inputField) {
      data.append(key, inputField[key]);
    }
    data.append("token", token);
    /* sending request to server */

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/news/update/` + urlData.id,
        data,
        {
          headers: {
            "Content-type": "multipart/form-data",
            ...headers,
          },
        }
      )
      .then((response) => {
        handleUpdateResponse(response);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //urlData update
  useEffect(() => {
    if (urlData.hasOwnProperty("id")) {
      let content = {
        params: {
          token,
        },
        headers: { ...headers },
      };
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/news/get/` + urlData.id,
          content
        )
        .then((response) => {
          console.log(response);
          setInputField((prev) => {
            let newData = {};
            for (const key in prev) {
              newData[key] =
                response.data[key] == null ? "" : response.data[key];
            }
            newData["image"] = 0;
            setIsUiReady(true);
            return newData;
          });
        });
      setPageInfo((prev) => {
        return {
          ...prev,
          title: "Update News",
        };
      });
    } else {
      setPageInfo((prev) => {
        return {
          ...prev,
          title: "Add News",
        };
      });

      setInputField((prev) => {
        let newData = {};
        for (const key in prev) {
          newData[key] = "";
        }
        newData["newscategoryid"] = 0;
        return newData;
      });

      setAlert((prev) => {
        return {
          ...prev,
          isOpen: false,
        };
      });
    }
  }, [urlData]);

  //fetching category
  useEffect(() => {
    scrollToHead();
    const content = {
      params: {
        token,
      },
      headers: {
        ...headers,
      },
    };
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/category/get`, content)
      .then((response) => {
        setIsUiReady(true);
        setCategory(response.data);
      })
      .catch((error) => {
        if (error.response) {
          let code = error.response.status;
          console.log(code);
          if (code === 400 || code === 404 || 401) {
            return (window.location = "/login");
          }
        }
      });
  }, []);
  return (
    <>
      <section style={{ marginBottom: "4rem" }}>
        <Heading
          icon={<AiOutlinePlus />}
          background={urlData.hasOwnProperty("id") ? green : primaryColor}
        >
          {pageInfo.title}
        </Heading>
        {/* failed alert */}
        {alert.isOpen && (
          <Alert
            type={alert.type}
            cancleHandler={() => {
              setAlert((prev) => {
                return { ...prev, isOpen: false };
              });
            }}
          >
            {alert.message}
          </Alert>
        )}
        {isUiReady && (
          <>
            <InputField
              type="text"
              value={inputField.title}
              label="News Title"
              name="title"
              onChangeHandler={onChangeHandler}
              isError={fieldError.title.isError}
              errorMessage={fieldError.title.errorMessage}
            ></InputField>
            <Grid container>
              <Grid item xs={12} md={6}>
                <SelectField
                  onChangeHandler={onChangeHandler}
                  name="newscategoryid"
                  value={inputField.newscategoryid}
                  isError={fieldError.newscategoryid.isError}
                  errorMessage={fieldError.newscategoryid.errorMessage}
                >
                  {category &&
                    category.map((category) => {
                      return (
                        <option
                          value={category.id}
                          key={category.id}
                          // selected={category.id === inputField.newscategoryid}
                        >
                          {category.name}
                        </option>
                      );
                    })}
                </SelectField>
              </Grid>
              <Grid item xs={12} md={6} style={{ paddingLeft: "1rem" }}>
                <InputField
                  type="text"
                  value={inputField.imagecaption}
                  label="Image Caption"
                  name="imagecaption"
                  onChangeHandler={onChangeHandler}
                  isError={fieldError.imagecaption.isError}
                  errorMessage={fieldError.imagecaption.errorMessage}
                ></InputField>
              </Grid>
            </Grid>

            <FileField
              type="file"
              label="Image"
              name="image"
              onChangeHandler={onChangeHandler}
              isError={fieldError.image.isError}
              errorMessage={fieldError.image.errorMessage}
            ></FileField>

            {/*  editor */}
            <div style={{ marginBottom: "1.2rem" }}>
              <TextEditor
                name="description"
                initialValue={inputField.description}
                onEditorChange={(content, editor) => {
                  setInputField((prev) => {
                    return {
                      ...prev,
                      description: content,
                    };
                  });
                }}
              />
            </div>
            <div
              style={{
                padding: "1rem 0",
                fontSize: "1.8rem",
                margin: "2rem 0",
                textTransform: "uppercase",
                display: "flex",
                alignItems: "center",
              }}
            >
              <AiOutlineFileSearch
                style={{ marginRight: "1rem", fontSize: "3rem" }}
              />
              <p>Seo Items</p>
            </div>
            <InputField
              type="text"
              value={inputField.meta_keyword}
              label="Project Tag"
              name="meta_keyword"
              onChangeHandler={onChangeHandler}
              isError={fieldError.meta_keyword.isError}
              errorMessage={fieldError.meta_keyword.errorMessage}
            ></InputField>
            <TextAreaField
              type="text"
              value={inputField.meta_description}
              label="Meta Description"
              name="meta_description"
              onChangeHandler={onChangeHandler}
              isError={fieldError.meta_description.isError}
              errorMessage={fieldError.meta_description.errorMessage}
            ></TextAreaField>
            <InputField
              type="text"
              value={inputField.meta_robots}
              label="Meta Robot"
              name="meta_robots"
              onChangeHandler={onChangeHandler}
              isError={fieldError.meta_robots.isError}
              errorMessage={fieldError.meta_robots.errorMessage}
            ></InputField>
            <Button
              onClick={
                urlData.hasOwnProperty("id") ? handleUpdate : handleSubmit
              }
            >
              {urlData.hasOwnProperty("id") ? "Update" : "Add"}
            </Button>
          </>
        )}
      </section>
      {!isUiReady && <LoadingModal />}
    </>
  );
}

export default AddNews;
